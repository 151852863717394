/**
 * @Author: Michael Neumair <mBook>
 * @Date:   2016-09-27T09:54:14+02:00
 * @Email:  7q7w7e7r@gmail.com
 * @Last modified by:   mBook
 * @Last modified time: 2016-10-10T11:39:02+02:00
 */

import {xhrLoadCid, xhrSaveCid} from './utilities/cidRequests';

// VERSION of build
// "GIT_VERSION" is macro that should be defined via webpack (DefinePlugin)
let VERSION = null;
if (GIT_VERSION) {
  VERSION = GIT_VERSION;
  if (console) {
    console.log('APP - BUILD_VERSION :: ' + GIT_VERSION);
  }
}

// react libraries
import React from 'react';
import ReactDOM from 'react-dom';

// react-router (and custom history)
import {Route, Switch, Redirect} from 'react-router';
import createHistory from 'history/createHashHistory';
import CustomHashRouter from './utilities/CustomHashRouter';

// helper libraries
import {Provider, connect} from 'react-redux';
import store from './checkout/store';
import {activeMaskAction} from 'checkout/actions/setActiveMaskActions';
import {animateScroll} from 'react-scroll';
import console from './utilities/console';
import initChat from './utilities/chatIntegration';
import {i18nInterface} from 'utilities/i18n_common';
import {setPartnerCode, setUceId, setSum, setFromURL} from './utilities/setDirectly';
import {handleCheckoutAsync} from 'utilities/handleCheckoutAsync';

/* lodash :: multiple helpers funcs */
import cloneDeep from 'lodash/cloneDeep';
import findKey from 'lodash/findKey';
import last from 'lodash/last';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import join from 'lodash/join';
import split from 'lodash/split';

// react redux form
import {actions} from 'react-redux-form/src';
import {closeHelp} from './checkout/actions/checkoutHelpActions';

// components
import AsyncLoader from 'checkout/components/AsyncLoader';
import MHintboxCheckout from 'checkout/components/MHintboxCheckout';
import ALinkColor from 'common/links/components/ALinkColor';
import ATextInputDescription
  from 'common/inputs/components/ATextInputDescription';

import {BUTTON_STYLE_FILLED} from './checkout/components/MNavigationBottomCheckout';

// value constants
import {FORM_MODEL} from './checkout/reducers/reducers';
import {getSellingOptions, setSellingOptions} from 'utilities/sellingOptions';
import {setCaptchaCompleted, getCaptchaData, isCaptchaCompleted} from 'utilities/captcha';

// utils
import {saveUceValues} from 'checkout/utils/userConcernProcessing';
import {
  applyMappingReverse,
  getFlatFormValues,
} from 'checkout/utils/getFormValues';
import isFormValid from 'checkout/utils/isFormValid';
import {
  setInfoLinks,
  getInfoLinks,
} from 'checkout/utils/infoLinks';

import isPromise from 'is-promise';

// config
import getMapping from 'config/mapping';
import {isKfx, useNewApi} from './config/constants.common';
import AModal from './checkout/components/AModal';
import {saveSubPartner} from './utilities/partnerProcessing';

import * as Sentry from '@sentry/react';

import queryString from 'query-string';

let customHistory = null;

/* recaptcha API KEY */
let recaptchaApiKey;

/**
 * setRecaptchaApiKey
 *
 * @param {string} str
 * @return {undefined}
 */
function setRecaptchaApiKey(str) {
  recaptchaApiKey = str;
}

/**
 * getRecaptchaApiKey
 *
 * @return {string}
 */
function getRecaptchaApiKey() {
  return recaptchaApiKey;
}

/* steps setter/getter */
let steps = [];


// init Sentry
Sentry.init({
  dsn: process.env.STAGE == "local" ? "http://sentry@localhost:23517/1" : "https://6f32f2a45ef9053b204ae7489d10cf77@o401565.ingest.us.sentry.io/4507848272969728",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.STAGE,
  release: process.env.RELEASE,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /\.kautionsfrei\.de$/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  denyUrls: [
    // Ignore errors from any gtm.js file
    /gtm\.js/i,
    // Ignore errors from Google Maps
    /maps\.googleapis\.com/i,
    // Ignore errors from any Visual Website Optimizer URLs
    /visualwebsiteoptimizer\.com/i,
    // Ignore errors from specific reCAPTCHA files
    /recaptcha\/releases\/[A-Za-z0-9_-]+\/recaptcha__de\.js/i,
  ],
  ignoreErrors: [
    'Non-Error promise rejection captured with value: Timeout',
    'Timeout (B)',
  ],
});

/**
 * setSteps
 * accepts array of strings which represent different steps of wizard
 * also setups navigation history (if there is more than one step)
 *
 * @param {array} array
 * @return {undefined}
 */
function setSteps(array = []) {
  steps = array;

  if (steps.length == 1) {
    // build an history object that has no function except an single location
    customHistory = {
      push: function(node) {
        // do nothing on history operation
      },
      replace: function(node) {
        // do nothing on history operation
      },
      listen: function(callback) {
        // do nothing on history operation
      },
      location: {
        state: undefined,
        hash: '',
      },
    };
    if (steps[0].indexOf('?') > -1) {
      customHistory.location.pathname = '/' + steps[0].split('?')[0];
      customHistory.location.search = '?' + steps[0].split('?')[1];
    } else {
      customHistory.location.pathname = '/' + steps[0];
      customHistory.location.search = '';
    }
  } else {
    customHistory = createHistory();
  }
}

/**
 * getNextStep
 * returns the next step as string
 *
 * @param {string} skipTo
 * @return {string}
 */
function getNextStep(skipTo) {
  const steps = getSteps();
  if (steps.indexOf(skipTo) >= 0) {
    return skipTo;
  }
  const currentStep = steps.indexOf(
      customHistory.location.pathname.substring(1));

  if (steps.length >= currentStep + 2) {
    return steps[(currentStep + 1)];
  }

  return skipTo;
}


/**
 * getSteps
 * returns array of steps which represent wizard steps available on this app
 *
 * @return {array}
 */
function getSteps() {
  return cloneDeep(steps);
}

/**
 * TODO jsdoc here
 *
 * @param {string} skipTo
 * @param {number} timeoutRef
 * @param {function} buildQuery
 * @return {undefined}
 */
function afterSaveSuccess(skipTo, timeoutRef = 0, buildQuery = (() => {
  return '';
})) {
  store.dispatch(actions.change(`${FORM_MODEL}.formHandling.forceToShowErrors`, false));

  const scrollStart = (typeof window != 'undefined' && window.OA_TOP_SCROLL_MIN) || 0;

  if ((/https?:\/\//).test(skipTo)) {
    clearTimeout(timeoutRef);
    if (document) {
      setTimeout(() => {
        document.location.href = skipTo;
      }, 400,
      );
    }
  } else {
    animateScroll.scrollTo(scrollStart);
    customHistory.push('/' + skipTo + buildQuery());
  }
}


/**
 * TODO jsdoc here
 *
 * @param {object} event
 * @param {string} action
 * @return {undefined}
 */
function linkPress(event, action) {
  const scrollStart = (typeof window != 'undefined' && window.OA_TOP_SCROLL_MIN)
      || 0;

  // abort if we are in interactive lock
  if (get(store.getState(),
      `${FORM_MODEL}.formHandling.interactiveLock`) == true) {
    event.stopPropagation();
    event.preventDefault();
    return;
  }

  /**
   * TODO jsdoc here
   *
   * @return {string}
   */
  function buildQuery() {
    let query = '?';
    if (store.cid != null) {
      query += 'cid=' + encodeURIComponent(store.cid);
    }
    query = (query == '?') ? '' : query;
    return query;
  }

  /**
   * TODO jsdoc here
   *
   * @return {number} setTimeout-return
   */
  function startIlockTimeout() {
    return setTimeout(
        () => {
          store.dispatch(actions.change(
              `${FORM_MODEL}.formHandling.interactiveLock`, false));
        },
        500,
    );
  }

  /**
   * TODO jsdoc here
   *
   * @return {undefined}
   */
  function resetCaptcha() {
    store.dispatch(actions.change(`${FORM_MODEL}.security.captchaResponseKey`, ''));
  }

  /**
   * TODO jsdoc here
   *
   * @param {object} errorsObject
   * @param {boolean} isMapped
   * @return {undefined}
   */
  function processErrors(errorsObject, isMapped = false) {
    for (var k in errorsObject) {
      const v = errorsObject[k];
      const tk = (isMapped && k) || findKey(getMapping(), (o) => {
        if (o == null) {
          return false;
        } else {
          return o == k || (o._key && o._key == k);
        }
      });
      if (tk && !tk.match(/\.id$/)) {
        const action_key = `${FORM_MODEL}.${tk}`;
        store.dispatch(actions.setValidity(action_key, false));
        store.dispatch(actions.setErrors(action_key, v['message'][0]));
        store.dispatch(actions.change(
            `${FORM_MODEL}.formHandling.forceToShowErrors`, true));
      }
    }
  }

  if (action == 'to:reservation') {
    store.dispatch(closeHelp());
    animateScroll.scrollTo(scrollStart);
    customHistory.push(
        '/tenant' +
        buildQuery() +
        (buildQuery() != '' ? '&reservation=true' : '?reservation=true'),
    );
    event.stopPropagation();
    event.preventDefault();
    return true;
  }
  if (action.match(/step:topnav:[0-9]+/)) {
    const currentStep = getSteps().indexOf(
        customHistory.location.pathname.substring(1),
    );
    // only backward navigation on top nav
    if (currentStep > parseInt(last(action.split(':')))) {
      store.dispatch(closeHelp());
      animateScroll.scrollTo(scrollStart);
      customHistory.push(
          '/' + getSteps()[last(action.split(':'))] + buildQuery(),
      );
    }
    event.stopPropagation();
    event.preventDefault();
    return true;
  }
  if (action.match(/step:edit:[0-9]+/)) {
    store.dispatch(closeHelp());
    animateScroll.scrollTo(scrollStart);
    customHistory.push(
        '/' + getSteps()[last(action.split(':'))] + buildQuery(),
    );
    return true;
  }
  if (action == 'step:next') {
    if (isFormValid() === true) {
      store.dispatch(closeHelp());

      if (!store.getState()[FORM_MODEL].formHandling.interactiveLock) {
        store.dispatch(actions.change(
            `${FORM_MODEL}.formHandling.interactiveLock`, true),
        );

        if (customHistory.location.pathname.substring(1) === 'uce') {
          (useNewApi() ? saveSubPartner(store) : saveUceValues(store)).then((xhr) => {
            const ilockTimeout = startIlockTimeout();
            const data = xhr.data;
            if (data.cid != null) {
              store.cid = data.cid;
            }
            if (isEmpty(data.errors)) {
              afterSaveSuccess(getNextStep(data.skip_to), ilockTimeout, buildQuery);
            } else {
              processErrors(
                  reduce(
                      data.errors,
                      (reduced, val, key) => {
                        reduced[`uce.${key}`] = {message: val};
                        return reduced;
                      },
                      {},
                  ),
                  true,
              );
            }
          }, (xhr) => {
            // error occured
            console.log(`saveUceValues Error for ${store.cid}`);
            if (xhr.status == 0) {
              console.log('CORS - not allowed');
            } else {
              console.log(xhr);
            }
            store.dispatch(actions.change(`${FORM_MODEL}.formHandling.interactiveLock`, false));
          });
        } else {
          xhrSaveCid(
              store.cid,
              customHistory.location.pathname.substring(1),
              isReservationActive(),
          ).then(
              (xhr) => {
                const ilockTimeout = startIlockTimeout();
                const data = JSON.parse(xhr.responseText || '' +
                    '{"errors": {"xhr": "unable to parse JSON (xhr)"}}');
                if (data.cid != null) {
                  store.cid = data.cid;
                }
                if (isEmpty(data.errors)) {
                  if (lastUserAction()) {
                    handleCheckoutAsync(store.cid, isReservationActive);
                  } else {
                    afterSaveSuccess(getNextStep(data.skip_to), ilockTimeout, buildQuery);
                  }
                } else {
                  if (data.errors.hasOwnProperty('captchaResponseKey')) {
                    resetCaptcha();
                  }
                  processErrors(data.errors);
                }
              }, (xhr) => {
                // error occured
                console.log(`XhrSaveCID Error for ${store.cid}`);
                if (xhr.status == 0) {
                  console.log('CORS - not allowed');
                } else {
                  console.log(xhr);
                }
                store.dispatch(actions.change(`${FORM_MODEL}.formHandling.interactiveLock`, false));
              },
          );
        }
      }
    } else {
      // do nothing here
    }
    return true;
  }
  if (action == 'step:prev') {
    store.dispatch(actions.change(`${FORM_MODEL}.formHandling.forceToShowErrors`, false));
    store.dispatch(closeHelp());
    const currentStep = getSteps().indexOf(
        customHistory.location.pathname.substring(1));
    if (currentStep > 0) {
      animateScroll.scrollTo(scrollStart);
      customHistory.push('/' + getSteps()[currentStep - 1] + buildQuery());
    }
    return true;
  }
  event.preventDefault();
}

/**
 * TODO jsdoc here
 *
 * @param {(object|null)} history
 * @return {boolean}
 */
function isReservationActive(history = null) {
  if (history == null) {
    history = customHistory;
  }
  const isReservationURL = (/reservation=((true)|(on)|(1))/).test(history.location.search.toLowerCase());
  const isReservationSetting = window && window.OA_RESERVATION;
  return isReservationURL || isReservationSetting;
}

/**
 * isLandlordOptional
 *
 * @return {boolean}
 */
function isLandlordOptional() {
  return !getSteps().includes('landlord');
}

/**
 * hideDeliveryAddress
 *
 * @return {boolean}
 */
function hideDeliveryAddress() {
  return window.OA_RECONF_TO && window.OA_RECONF_TO === 'rundv_kf_09_2019';
}

/**
 * getForms
 *
 * @param {string} step
 * @return {(array|object)}
 */
function getForms() {
  const step = getStepFromHistory();
  switch (step) {
    case 'start':
      return <AsyncLoader
        asyncComponent={import(
            /* webpackChunkName: "OFormCheckoutStart" */
            'checkout/containers/OFormCheckoutStart'
        )}
        supportOverMaxDeposit={isKfx()}
      />;
    case 'object':
      if (isKfx()) {
        store.dispatch(activeMaskAction([], false));
        return [
          <AsyncLoader
            asyncComponent={import(
                /* webpackChunkName: "OFormCheckoutStart" */
                'checkout/containers/OFormCheckoutStart'
            )}
            key="start-form"
            prefillTarget="newContract"
            supportOverMaxDeposit={isKfx()}
            activeMaskMerge={true}
          />,
          <AsyncLoader
            asyncComponent={import(
                /* webpackChunkName: "OFormCheckoutObject" */
                'checkout/containers/OFormCheckoutObject'
            )}
            key="object-form"
            activeMaskMerge={true}
            withRiskQuestions={isKfx()}
          />,
          <AsyncLoader
            asyncComponent={import(
                /* webpackChunkName: "OFormCheckoutExtraQuestions" */
                'checkout/containers/OFormCheckoutExtraQuestions'
            )}
            key="extra-questions-form"
            activeMaskMerge={true}
          />,
        ];
      } else {
        return <AsyncLoader
          asyncComponent={import(
              /* webpackChunkName: "OFormCheckoutObject" */
              'checkout/containers/OFormCheckoutObject'
          )}
          key="object-form"
          isLandlordOptional={isLandlordOptional()}
          skipMoveDate={true}
          skipColdRent={true}
        />;
      }
    case 'tenant':
      if (isReservationActive()) {
        return <AsyncLoader
          asyncComponent={import(
              /* webpackChunkName: "OFormCheckoutFirstTenant" */
              'checkout/containers/OFormCheckoutFirstTenant'
          )}
          isSingleTenant={true}
          isNationalityMandatory={!isKfx()}
        />;
      } else {
        store.dispatch(activeMaskAction([], false));
        return [
          React.createElement(
              connect(
                  (state) => {
                    return {
                      key: 'hint',
                      title: i18nInterface('note'),
                      hint: i18nInterface('non_editable_lessee_after_reservation'),
                      actions: <div>{React.createElement(ALinkColor, {
                        style: {marginRight: 0},
                        onPress: () => {
                          // navigate directly here and clean cid
                          store.cid = null;
                          // clean state
                          store.dispatch(actions.change('checkout.formHandling.contractState', null));
                          // clean id fields
                          let currentPath = '';
                          // recursive function to find all storage fields
                          // that are id's (of server)
                          const findIdKeys = (oj) => {
                            const result = [];
                            for (const i in oj) {
                              if (typeof oj[i] == 'object' && oj[i] != null) {
                                if (currentPath == '') {
                                  currentPath = i;
                                } else {
                                  currentPath = currentPath + '.' + i;
                                }
                                ;
                                const recDown = findIdKeys(oj[i]);
                                for (let k = 0; k < recDown.length; k++) {
                                  result.push(recDown[k]);
                                }
                                if ((/^[^\.]*$/).test(currentPath)) {
                                  currentPath = '';
                                } else {
                                  currentPath =
                                      currentPath.replace(/\.[^\.]+$/, '');
                                }
                                ;
                              } else {
                                if (currentPath == '') {
                                  result.push(i);
                                } else {
                                  result.push(currentPath + '.' + i);
                                }
                              }
                            }
                            return filter(result, (c) => {
                              return (/\.id$/).test(c) && !(/^forms\./).test(c) &&
                                  !(/^activeMask\./).test(c);
                            });
                          };
                          // find with function and set to null (id fields)
                          findIdKeys(store.getState().checkout).forEach((field) => {
                            store.dispatch(
                                actions.change('checkout.' + field, null));
                          });
                          // and redirect to new page "Sofortzusage"
                          customHistory.push('/tenant?reservation=true');
                        },
                      }, i18nInterface('changePersonelData'))}
                      {React.createElement(ALinkColor, {
                        style: {marginLeft: '20px'},
                        onPress: () => {
                          linkPress(event, 'step:next');
                        },
                      }, i18nInterface('continueWithApplication'))}
                      </div>,
                      style: {
                        display: (state.checkout.formHandling.contractState ==
                            'not_completed_reservation') ? null : 'none',
                      },
                    };
                  },
              )(MHintboxCheckout),
          ),
          <AsyncLoader
            asyncComponent={import(
                /* webpackChunkName: "OFormCheckoutFirstTenant" */
                'checkout/containers/OFormCheckoutFirstTenant'
            )}
            activeMaskMerge={true}
            isSingleTenant={false}
            isNationalityMandatory={!isKfx()}
          />,
          <AsyncLoader
            asyncComponent={import(
                /* webpackChunkName: "OFormCheckoutSecondTenant" */
                'checkout/containers/OFormCheckoutSecondTenant'
            )}
            activeMaskMerge={true}
            withPartnerCode={(getSteps().indexOf('start') > -1)}
            hidePartnerCode={store.getState().checkout.billing.partner_code != null && store.getState().checkout.billing.partner_code != ''}
            isNationalityMandatory={!isKfx()}
          />,
        ];
      }
    case 'landlord':
      return [
        React.createElement(
            connect(
                (state) => {
                  const isReservation = state.checkout.formHandling.contractState !=
                      null &&
                      !state.checkout.formHandling.contractState.match(/not_reservation$/) &&
                      state.checkout.formHandling.contractState.match(/reservation$/);
                  return {
                    key: 'hint',
                    title: i18nInterface('label.landlord.title'),
                    hint: i18nInterface('label.landlord.hint'),
                    actions: React.createElement(ALinkColor, {
                      onPress: (event) => {
                        linkPress(event, 'to:reservation');
                      },
                    }, i18nInterface('label.landlord.action')),
                    style: {
                      display: (isReservation) ? 'none' : null,
                    },
                  };
                },
            )(MHintboxCheckout),
        ),
        <AsyncLoader
          asyncComponent={import(
              /* webpackChunkName: "OFormCheckoutLandlord" */
              'checkout/containers/OFormCheckoutLandlord'
          )}
          key="oformcheckoutlandlord"
          sendToLandlordAllowed={getSteps().indexOf('start') > -1}
        />,
      ];
    case 'payment':
      return (
        <AsyncLoader
          asyncComponent={import(
              /* webpackChunkName: "OFormCheckoutPayment" */
              'checkout/containers/OFormCheckoutPayment'
          )}
          // recaptcha api key
          recaptchaApiKey={getRecaptchaApiKey()}
          isCaptchaCompleted={isCaptchaCompleted()}
          captchaData={getCaptchaData()}
          // url to webpage with templates for the landlord
          urlToTemplatesForLandlord={'https://kautionsfrei.de/files/KF_Infoflyer_Vermieter.pdf'}
          sendToLandlordAllowed={getSteps().indexOf('start') > -1}
          isLandlordOptional={isLandlordOptional()}
          noDeliveryAddress={hideDeliveryAddress()}
        />
      );
    case 'check':
      const formComponent = [
        <AsyncLoader
          asyncComponent={import(
              /* webpackChunkName: "OFormCheckoutCheck" */
              'checkout/containers/OFormCheckoutCheck'
          )}
          // info documents
          onPressInfoLinks={getInfoLinks()}
          stepsForEditCallback={getSteps()}
          onPressEditCallback={linkPress}
          withRiskQuestions={isKfx()}
          isLandlordOptional={isLandlordOptional()}
          isNationalityMandatory={!isKfx()}
          noDeliveryAddress={hideDeliveryAddress()}
          activeMaskMerge={true}
        />,
      ];
      if (getSteps().indexOf('selling') == -1) {
        formComponent.unshift(
            <AsyncLoader
              asyncComponent={import(
                  /* webpackChunkName: "OFormCheckoutSelling" */
                  'checkout/containers/OFormCheckoutSelling'
              )}
              sellingOptions={getSellingOptions()}
            />,
        );
      }
      return formComponent.length == 1 ? formComponent[0] : formComponent;
    case 'selling':
      return <AsyncLoader
        asyncComponent={import(
            /* webpackChunkName: "OFormCheckoutSelling" */
            'checkout/containers/OFormCheckoutSelling'
        )}
        sellingOptions={getSellingOptions()}
      />;
  }
}

/**
 * gets current navigation history step
 *
 * @return {string}
 */
function getStepFromHistory() {
  return customHistory.location.pathname.substring(1);
}

/**
 * returns true if new api is used and user clicked on close the deal or on reserve the deal
 *
 * @return {boolean}
 */
function lastUserAction() {
  return useNewApi() &&
      (getStepFromHistory() === 'check' || (getStepFromHistory() === 'tenant' && isReservationActive()));
};

/**
 * @param {{}} objectToFlatten
 * @return {{}}
 */
function flattenObject(objectToFlatten) {
  objectToFlatten = getFlatFormValues(objectToFlatten, {}, '');
  const flattenedObejct = {};
  for (const key in objectToFlatten) {
    const keyParts = key.split('.');
    const newKey = keyParts.reduce((accumulator, currentValue) => {
      if (typeof currentValue === 'undefined') {
        return accumulator;
      }

      return `${accumulator}[${currentValue}]`;
    });
    flattenedObejct[newKey] = objectToFlatten[key];
  }
  return flattenedObejct;
}

/**
 * initApp
 *
 * @param {string} step
 * @return {undefined}
 */
function initApp(step) {
  /**
   * first load chat integration
   * (second parameter is for async evaluation)
   */
  initChat(window.SNAPENGAGE_API_KEY, true);

  const renderTarget = document.getElementById('jsapp');

  /**
   * sets the active state of the steps array of the progress bar
   * @param  {array} steps
   *         array with step-objestv
   * @param  {Number} currPageNumber
   *         current page number to set the active state of the
   *         steps. starts at 1.
   * @return {Array}
   *         array for the steps prop of a page
   */
  function setActiveStateForSteps() {
    const stepsArray = getSteps();
    const currStepName = customHistory.location.pathname.substring(1);
    const currPageNumber = getSteps().indexOf(currStepName);

    return stepsArray.map((step, index) => {
      return {
        active: (index === currPageNumber),
        key: step,
        label: i18nInterface('navigate.top.' + step),
        onPress: (event) => {
          linkPress(event, 'step:topnav:' + index);
        },
        hide: (['start'].includes(step) || isReservationActive()),
        showStep: step !== 'check',
      };
    });
  }

  /**
   * refreshStoreOnNavigate
   *
   * @param {(object|null)} location
   * @return {undefined}
   */
  function refreshStoreOnNavigate(location = null) {
    store.dispatch(actions.change(
        `${FORM_MODEL}.formHandling.interactiveLock`, true));

    if (location != null && location.search != '') {
      const cidRegex = (/cid=([^&]+)/);
      if (cidRegex.test(location.search)) {
        store.cid = decodeURIComponent(
            last(
                cidRegex.exec(location.search),
            ),
        );
      }
    }

    if (store.cid != null) {
      xhrLoadCid(store.cid).then((xhr) => {
        try {
          var decodedXhr = JSON.parse(xhr.responseText);
        } catch (exception) {
          console.log('unable to parse cid_to_json answer');
          return;
        }

        if (decodedXhr['errors'] && decodedXhr['errors'].length > 0) {
          store.cid = null;
          customHistory.push(`/error?keys=${join(
              decodedXhr['errors'].map((error) => (error.hasOwnProperty('code') ? error.code : error).toLowerCase()), ','
          )}`);
          return;
        }

        if (decodedXhr['__cid']) {
          const nextPath = location.pathname || getSteps()[0];
          const cidUriEncode = encodeURIComponent(
              decodedXhr['__cid'],
          );
          customHistory.push(nextPath + '?cid=' + cidUriEncode);
          return;
        }

        if (useNewApi()) {
          // this is needed, as the new kfx api returns deep objects
          // flattened obejcts do not change
          decodedXhr = flattenObject(decodedXhr);
        }

        const data = applyMappingReverse(getMapping(), decodedXhr);

        for (const k in data) {
          if (data[k] != null) {
            store.dispatch(actions.setValidity(k, true));
            store.dispatch(actions.change(k, data[k]));
            store.dispatch(actions.blur(k));
          }
          // processing second tenant
          if (k == 'checkout.secondTenant.id' && parseInt(data[k]) > 0) {
            store.dispatch(actions.change('checkout.objective.useSecondTenant',
                true));
          }
        }
        store.dispatch(actions.change(
            `${FORM_MODEL}.formHandling.interactiveLock`, false));
      }, (xhr) => {
        // error occured
        console.log(`XhrLoadCID Error for ${store.cid}`);
        if (xhr.status == 0) {
          console.log('CORS - not allowed');
        } else {
          console.log(xhr);
        }
        store.dispatch(actions.change(
            `${FORM_MODEL}.formHandling.interactiveLock`, false));
      });
    } else {
      store.dispatch(actions.change(
          `${FORM_MODEL}.formHandling.interactiveLock`, false));
    }
  }

  /**
   * getApp
   *
   * @return {object}
   */
  function getApp() {
    return (
      <CustomHashRouter
        refreshListener={refreshStoreOnNavigate}
        externalHistory={customHistory}
      >
        <Switch>
          <Redirect exact from='/' to={{
            pathname: '/' + getSteps()[0],
          }}/>
          <Route path="/error" component={(props) => {
            const {location} = props;
            const allError = (/keys=([a-z_,-]+)/).test(location.search) &&
                  split(
                      (/keys=([a-z_,-]+)/).exec(location.search)[1],
                      ',',
                  );

            return (<Provider store={store}>
              <AsyncLoader
                asyncComponent={import(
                    /* webpackChunkName: "TCheckoutPage" */
                    'checkout/containers/TCheckoutPage'
                )}
                formModel={FORM_MODEL}
                forms={
                  [
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: '30px',
                      }}
                      key="errors-icon"
                    >
                      <span
                        className="a-icon a-icon-inline glyphicons glyphicons-exclamation-sign huge error"
                      >
                      </span>
                    </div>,
                  ].concat(
                      map(allError, (e) => (
                        <span key={e}>{i18nInterface(`error.${e}`)}</span>)),
                  )
                }
                steps={[
                  {
                    active: true,
                    key: 'error',
                    label: 'error',
                    hide: true,
                  }]}
                loadSidebarContent={'error'}
                usePlainChildren={true}
              />
            </Provider>);
          }}/>
          <Route path="/uce" component={() => {
            // Check if we have valid vm_id and uvm_id parameters in the URL
            const urlParams = queryString.parse(window.location.search);
            const vmId = urlParams.vm_id;
            const uvmId = urlParams.uvm_id;
            
            // If we have valid parameters, automatically click the "Next" button
            if (vmId && uvmId) {
              // We need to wait for the component to mount before clicking the button
              setTimeout(() => {
                linkPress(null, 'step:next');
              }, 100);
            }
            
            //Skip uce modal if passed via import script
            if (store.getState().checkout.uce.subPartnerCode) {
              linkPress(null, 'step:next');
            }
            return (<Provider store={store}>
              <AsyncLoader
                asyncComponent={import(
                    /* webpackChunkName: "TCheckoutPage" */
                    'checkout/containers/TCheckoutPage'
                )}
                formModel={FORM_MODEL}
                forms={
                  <AsyncLoader
                    asyncComponent={import(
                        /* webpackChunkName: "OFormCheckoutUce" */
                        'checkout/containers/OFormCheckoutUce'
                    )}
                    cId={store.cid}
                  />
                }
                steps={setActiveStateForSteps()}
                // visual stuff
                loadSidebarContent={'uce'}
                // prev and next buttons
                onPressLinkPrevious={(event) => {
                  // do nothing here
                }}
                onPressButtonForward={(event) => {
                  linkPress(event, 'step:next');
                }}
              />
            </Provider>);
          }}/>
          <Route path="/start" component={() => {
            if (getSteps().indexOf('start') > -1) {
              // Check if we have valid vm_id and uvm_id parameters in the URL
              const urlParams = queryString.parse(window.location.search);
              const vmId = urlParams.vm_id;
              const uvmId = urlParams.uvm_id;
              
              // If we have valid parameters, remove the UCE step from steps if it exists
              if (vmId && uvmId) {
                const currentSteps = getSteps();
                const uceIndex = currentSteps.indexOf('uce');
                if (uceIndex !== -1) {
                  const newSteps = [...currentSteps];
                  newSteps.splice(uceIndex, 1);
                  setSteps(newSteps);
                }
              }
              
              return (<Provider store={store}>
                <AsyncLoader
                  asyncComponent={import(
                      /* webpackChunkName: "TCheckoutPage" */
                      'checkout/containers/TCheckoutPage'
                  )}
                  formModel={FORM_MODEL}
                  forms={getForms()}
                  steps={setActiveStateForSteps()}
                  // visual stuff
                  loadSidebarContent={getStepFromHistory()}
                  // prev and next buttons
                  onPressLinkPrevious={(event) => {
                    linkPress(event, 'step:prev');
                  }}
                  onPressButtonForward={(event) => {
                    linkPress(event, 'step:next');
                  }}
                />
              </Provider>);
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
          <Route path="/tenant" component={(history) => {
            if (getSteps().indexOf('tenant') > -1) {
              return isReservationActive(history) ? (
                    <Provider store={store}>
                      <AsyncLoader
                        asyncComponent={import(
                            /* webpackChunkName: "TCheckoutPage" */
                            'checkout/containers/TCheckoutPage'
                        )}
                        formModel={FORM_MODEL}
                        forms={getForms()}
                        steps={setActiveStateForSteps()}
                        // visual stuff
                        loadSidebarContent={'reservierung'}
                        onPressLinkPrevious={
                          (event) => {
                            if (getSteps().indexOf('start') > -1) {
                              linkPress(event, 'step:prev');
                            } else {
                              // this a kfx hack
                            }
                          }
                        }
                        onPressButtonForward={(event) => {
                          linkPress(event, 'step:next');
                        }}
                        subButtonText={i18nInterface('reservation_sub_button_text')}
                      />
                    </Provider>
                ) : (
                    <Provider store={store}>
                      <AsyncLoader
                        asyncComponent={import(
                            /* webpackChunkName: "TCheckoutPage" */
                            'checkout/containers/TCheckoutPage'
                        )}
                        formModel={FORM_MODEL}
                        forms={getForms()}
                        steps={setActiveStateForSteps()}
                        // visual stuff
                        loadSidebarContent={getStepFromHistory()}
                        onPressLinkPrevious={
                          (event) => {
                            if (getSteps().indexOf('start') > -1) {
                              linkPress(event, 'step:prev');
                            } else {
                              // this a kfx hack
                            }
                          }
                        }
                        onPressButtonForward={(event) => {
                          linkPress(event, 'step:next');
                        }}
                      />
                    </Provider>
                );
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
          <Route path="/landlord" component={() => {
            if (getSteps().indexOf('landlord') > -1) {
              return (<Provider store={store}>
                <AsyncLoader
                  asyncComponent={import(
                      /* webpackChunkName: "TCheckoutPage" */
                      'checkout/containers/TCheckoutPage'
                  )}
                  formModel={FORM_MODEL}
                  forms={getForms()}
                  steps={setActiveStateForSteps()}
                  // visual stuff
                  loadSidebarContent={getStepFromHistory()}
                  // prev and next buttons
                  onPressLinkPrevious={(event) => {
                    linkPress(event, 'step:prev');
                  }}
                  onPressButtonForward={(event) => {
                    linkPress(event, 'step:next');
                  }}
                />
              </Provider>);
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
          <Route path="/selling" component={() => {
            return (<Provider store={store}>
              <AsyncLoader
                asyncComponent={import(
                    /* webpackChunkName: "TCheckoutPage" */
                    'checkout/containers/TCheckoutPage'
                )}
                formModel={FORM_MODEL}
                forms={getForms()}
                steps={setActiveStateForSteps(steps)}
                // visual stuff
                loadSidebarContent={getStepFromHistory()}
                // prev and next buttons
                onPressLinkPrevious={(event) => {
                  linkPress(event, 'step:prev');
                }}
                onPressButtonForward={(event) => {
                  linkPress(event, 'step:next');
                }}
              />
            </Provider>);
          }}/>
          <Route path="/object" component={() => {
            if (getSteps().indexOf('object') > -1) {
              return (<Provider store={store}>
                <AsyncLoader
                  asyncComponent={import(
                      /* webpackChunkName: "TCheckoutPage" */
                      'checkout/containers/TCheckoutPage'
                  )}
                  formModel={FORM_MODEL}
                  forms={getForms()}
                  steps={setActiveStateForSteps()}
                  // visual stuff
                  loadSidebarContent={getStepFromHistory()}
                  // prev and next buttons
                  onPressLinkPrevious={(event) => {
                    linkPress(event, 'step:prev');
                  }}
                  onPressButtonForward={(event) => {
                    linkPress(event, 'step:next');
                  }}
                />
              </Provider>);
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
          <Route path="/payment" component={() => {
            if (getSteps().indexOf('payment') > -1) {
              return (<Provider store={store}>
                <AsyncLoader
                  asyncComponent={import(
                      /* webpackChunkName: "TCheckoutPage" */
                      'checkout/containers/TCheckoutPage'
                  )}
                  formModel={FORM_MODEL}
                  forms={getForms()}
                  steps={setActiveStateForSteps(steps)}
                  // visual stuff
                  loadSidebarContent={getStepFromHistory()}
                  // prev and next buttons
                  onPressLinkPrevious={(event) => {
                    linkPress(event, 'step:prev');
                  }}
                  onPressButtonForward={(event) => {
                    linkPress(event, 'step:next');
                  }}
                />
              </Provider>);
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
          <Route path="/check" component={() => {
            if (getSteps().indexOf('check') > -1) {
              return (<Provider store={store}>
                <AsyncLoader
                  asyncComponent={import(
                      /* webpackChunkName: "TCheckoutPage" */
                      'checkout/containers/TCheckoutPage'
                  )}
                  formModel={FORM_MODEL}
                  forms={getForms()}
                  steps={setActiveStateForSteps()}
                  // visual stuff
                  loadSidebarContent={getStepFromHistory()}
                  // prev and next buttons
                  onPressLinkPrevious={(event) => {
                    linkPress(event, 'step:prev');
                  }}
                  onPressButtonForward={(event) => {
                    linkPress(event, 'step:next');
                  }}
                  styleButtonForward={BUTTON_STYLE_FILLED}
                  childrenHandlesCheckout={true}
                />
              </Provider>);
            } else {
              return <Redirect to={'/' + getSteps()[0]}/>;
            }
          }}/>
        </Switch>
      </CustomHashRouter>
    );
  }

  if (renderTarget) {
    if (isPromise(i18nInterface('i18n_version'))) {
      // this is a mini waiting app
      ReactDOM.render(
          <div style={{textAlign: 'center'}}>
            <div style={{margin: '30px 0 16px'}}>
              <span className="a-spinner a-spinner-square"></span>
            </div>
            <ATextInputDescription>
              using fallback translations
            </ATextInputDescription>
          </div>,
          renderTarget,
      );

      // unmount waiting app if loaded and mount real app
      i18nInterface('i18n_version').then(() => {
        ReactDOM.unmountComponentAtNode(renderTarget) &&
        ReactDOM.render(getApp(), renderTarget);
      });
    } else {
      // we have a preloaded i18n object
      ReactDOM.render(getApp(), renderTarget);
    }
  }
}

export {
  initApp,
  xhrLoadCid,
  setSteps,
  getSteps,
  getNextStep,
  setRecaptchaApiKey,
  getRecaptchaApiKey,
  setCaptchaCompleted,
  store,
  setInfoLinks,
  getInfoLinks,
  customHistory,
  setPartnerCode,
  setUceId,
  setSum,
  setFromURL,
  getSellingOptions,
  setSellingOptions,
  VERSION,
};

