import store from 'checkout/store';
import queryString from 'query-string';
import {actions} from 'react-redux-form/src';
import {FORM_MODEL} from 'checkout/reducers/reducers';
import {
  activeMaskAction,
} from 'checkout/actions/setActiveMaskActions';
import {
  loadUceData,
} from 'checkout/utils/userConcernProcessing.js';
import {setSubPartner} from './partnerProcessing.js';
import get from 'lodash/get';
import {useNewApi} from '../config/constants.common';

export const storeKeyForPartnerCode = `${FORM_MODEL}.billing.partner_code`;
export const storeKeyForHidePartnerCode = `${FORM_MODEL}.billing.hide_partner_code`;
export const storeKeyForSum = `${FORM_MODEL}.objective.deposit`;
export const storeKeyForIsExisting = `${FORM_MODEL}.objective.target`;

/*
 * setFromUrl uses location.search to find these GET Parameters:
 *
 *  * prefill_data : JSON String with this format
 *  == {
 *  ==   "apartment" : {
 *  ==       "sum": "12345|any number",
 *  ==       "is_existing": "true|boolean as string"
 *  ==   }
 *  == }
 *  * vm_id : string that holds partner_code value
 *
 *  @param {object} location
 *  @return {undefined}
 */
export function setFromURL(location) {
  // Parameter: Location >> Object Interface
  // https://developer.mozilla.org/en-US/docs/Web/API/Location

  const prefillData = JSON.parse(queryString.parse(location.search)['prefill_data'] || '{}');
  const partnerCode = queryString.parse(location.search)['vm_id'];
  const uvmCode = queryString.parse(location.search)['uvm_id'];

  if ( prefillData ) {
    if ( get( prefillData, 'apartment.sum') ) {
      const parsedValue = parseFloat(
          get( prefillData, 'apartment.sum')
      );

      if ( !Number.isNaN(parsedValue) ) {
        store.dispatch( actions.change(
            storeKeyForSum,
            parsedValue
        ));

        store.dispatch( actions.setValidity(
            storeKeyForSum,
            true
        ));

        store.dispatch( activeMaskAction(
            storeKeyForSum,
            true
        ));
      }
    }

    if ( get( prefillData, 'apartment.is_existing') ) {
      let valueIsExisting = null;
      if ( get( prefillData, 'apartment.is_existing') == 'true' ) {
        valueIsExisting = 'retrieveDeposit';
      } else {
        valueIsExisting = 'newContract';
      }
      store.dispatch( actions.change(
          storeKeyForIsExisting,
          valueIsExisting
      ));

      store.dispatch( activeMaskAction(
          storeKeyForIsExisting,
          true
      ));
    }
  }

  if ( partnerCode ) {
    setPartnerCode(partnerCode);
    if (uvmCode) {
      // Set the UCE ID in the store
      store.dispatch(actions.change(`${FORM_MODEL}.uce.subPartnerCode`, uvmCode));
      
      // Mark the UCE ID as active in the store to ensure it's included in submissions
      store.dispatch(activeMaskAction(
        `${FORM_MODEL}.uce.subPartnerCode`,
        true
      ));
      
      // Save the UCE ID to the server
      setSubPartner(store, uvmCode);
    }
  }
}

export function setPartnerCode(partnerCode) {
  store.dispatch( actions.change(storeKeyForPartnerCode, partnerCode));
  if (partnerCode !== null && partnerCode !== '') {
    store.dispatch( actions.change(storeKeyForHidePartnerCode, true));
  } else if (useNewApi()) {
    return;
  }


  loadUceData(partnerCode, store.cid).then(
      (xhr) => {
        const data = xhr.data;
        if ( data.length > 0 && !store.cid ) {
          // Valid config available and not already saved
          // Use public API to navigate to new step
          js_plusforta_com &&
                    js_plusforta_com.customHistory &&
                    js_plusforta_com.customHistory.push('/uce');
        }
      }
  ).catch((error) => {
    if (error.response && error.response.status !== 404) {
      throw error;
    }
  });
      // Non-relevant error spammed Sentry
      // (xhr) => {
      //   warning(false, 'error event occured on loadUceRequest() inside setPartnerCode()');
      // }

  // set active mask to have partnercode stored on next save
  store.dispatch( activeMaskAction(
      storeKeyForPartnerCode,
      true
  ));
}

export function setUceId(uceId) {
  setSubPartner(store, uceId);
}

export function setSum(sumValue) {
  store.dispatch( actions.change(storeKeyForSum, sumValue));
  // do not set activeMask here cause value will only be transfered
  // after regular input is shown
}

